import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./pages/authentication/login/login.module").then(
        (m) => m.LoginModule
      ),
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/authentication/login/login.module").then(
        (m) => m.LoginModule
      ),
    pathMatch: "full",
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./pages/authentication/logout/logout.module").then(
        (m) => m.LogoutModule
      ),
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "apps/pedidos-pienso",
        loadChildren: () =>
          import(
            "./pages/apps/pedidos-pienso/historico-pedidos/pedidos-pienso.module"
          ).then((m) => m.PedidosPiensoModule),
      },
      {
        path: "apps/add-pedido-pienso",
        loadChildren: () =>
          import(
            "./pages/apps/pedidos-pienso/add-pedido-pienso/add-pedidos-pienso.module"
          ).then((m) => m.AddPedidosPiensoModule),
      },
      {
        path: "apps/pedidos-medicamentos",
        loadChildren: () =>
          import(
            "./pages/apps/pedidos-medicamentos/historico-pedidos/pedidos-medicamentos.module"
          ).then((m) => m.PedidosMedicamentosModule),
      },
      {
        path: "apps/add-pedido-medicamentos",
        loadChildren: () =>
          import(
            "./pages/apps/pedidos-medicamentos/add-pedido-medicamentos/add-pedidos-medicamentos.module"
          ).then((m) => m.AddPedidosMedicamentosModule),
      },
      {
        path: "apps/historico-bajas",
        loadChildren: () =>
          import(
            "./pages/apps/baja-animales/historico-bajas/historico-bajas-animales.module"
          ).then((m) => m.HistoricoBajaAnimalesModule),
      },

      {
        path: "apps/parte-bajas",
        loadChildren: () =>
          import(
            "./pages/apps/baja-animales/parte-bajas/parte-bajas.module"
          ).then((m) => m.ParteBajasComponentModule),
      },
      {
        path: "apps/usuarios",
        loadChildren: () =>
          import("./pages/apps/usuarios/usuarios.module").then(
            (m) => m.UsuariosModule
          ),
      },
      {
        path: "apps/usuarios/form",
        loadChildren: () =>
          import(
            "./pages/apps/usuarios/usuarios-form/usuarios-form.module"
          ).then((m) => m.UsuariosFormModule),
      },
      {
        path: "apps/usuarios/form/:id",
        loadChildren: () =>
          import(
            "./pages/apps/usuarios/usuarios-form/usuarios-form.module"
          ).then((m) => m.UsuariosFormModule),
      },
      {
        path: "apps/configuracion",
        loadChildren: () =>
          import("./pages/apps/configuracion/configuracion.module").then(
            (m) => m.ConfiguracionModule
          ),
      },
      {
        path: "components",
        loadChildren: () =>
          import("./pages/components/components.module").then(
            (m) => m.ComponentsModule
          ),
      },
      {
        path: "forms/form-elements",
        loadChildren: () =>
          import("./pages/forms/form-elements/form-elements.module").then(
            (m) => m.FormElementsModule
          ),
      },
      {
        path: "forms/form-wizard",
        loadChildren: () =>
          import("./pages/forms/form-wizard/form-wizard.module").then(
            (m) => m.FormWizardModule
          ),
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./pages/icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "page-layouts",
        loadChildren: () =>
          import("./pages/page-layouts/page-layouts.module").then(
            (m) => m.PageLayoutsModule
          ),
      },
      {
        path: "tables/all-in-one-table",
        loadChildren: () =>
          import(
            "./pages/tables/all-in-one-table/all-in-one-table.module"
          ).then((m) => m.AllInOneTableModule),
      },
      {
        path: "blank",
        loadChildren: () =>
          import("./pages/blank/blank.module").then((m) => m.BlankModule),
      },
      {
        path: "level1/level2/level3/level4/level5",
        loadChildren: () =>
          import("./pages/level5/level5.module").then((m) => m.Level5Module),
      },

      //? Auditorias rutas
      {
        path: "apps/auditorias-granja",
        loadChildren: () =>
          import(
            "./pages/apps/auditorias/auditoria-granja/auditoria-granja.module"
          ).then((m) => m.AuditoriaGranjaModule),
      },
      {
        path: "apps/auditoria-granja",
        loadChildren: () =>
          import(
            "./pages/apps/auditorias/add-auditoria-granja/add-auditoria-granja.module"
          ).then((m) => m.AddAuditoriaGranjaModule),
      },
      {
        path: "apps/add-regas-granja/:id",
        loadChildren: () =>
          import(
            "./pages/apps/auditorias/add-regas-granja/add-regas-granja.module"
          ).then((m) => m.AddRegasGranjaModule),
      },
      {
        path: "apps/regas-granja",
        loadChildren: () =>
          import(
            "./pages/apps/auditorias/regas-granja/regas-granja.module"
          ).then((m) => m.RegasGranjaModule),
      },
      {
        path: "apps/plantilla-auditoria",
        loadChildren: () =>
          import(
            "./pages/apps/auditorias/plantilla-granja/plantilla-granja.module"
          ).then((m) => m.PlantillaGranjaModule),
      },
      {
        path: "apps/estadisticas-auditoria",
        loadChildren: () =>
          import(
            "./pages/apps/auditorias/estadisticas-auditoria/estadisticas-auditoria.module"
          ).then((m) => m.EstadisticasAuditoriaModule),
      },
      {
        path: "apps/add-plantilla-auditoria/:id",
        loadChildren: () =>
          import(
            "./pages/apps/auditorias/add-plantilla-granja/add-plantilla-granja.module"
          ).then((m) => m.AddPlantillaGranjaModule),
      },

      //? Notificaciones
      {
        path: "apps/notificaciones",
        loadChildren: () =>
          import(
            "./pages/apps/notificaciones/notificaciones/notificaciones.module"
          ).then((m) => m.NotificacionesModule),
      },
      {
        path: "apps/add-notificaiones",
        loadChildren: () =>
          import(
            "./pages/apps/notificaciones/add-notificaciones/add-notificaciones.module"
          ).then((m) => m.AddNotificacionesModule),
      },
      {
        path: "apps/historico-notificaiones",
        loadChildren: () =>
          import(
            "./pages/apps/notificaciones/historico-notificaciones/historico-notificaciones.module"
          ).then((m) => m.HistoricoNotificacionesModule),
      },
      {
        path: "apps/dias-pedido-granja",
        loadChildren: () =>
          import(
            "./pages/apps/notificaciones/dias-pedido-granja/dias-pedido-granja.module"
          ).then((m) => m.DiasPedidoGranjaModule),
      },
      {
        path: "apps/add-dias-pedido-granja/:id",
        loadChildren: () =>
          import(
            "./pages/apps/notificaciones/add-dias-pedido-granja/add-dias-pedido-granja.module"
          ).then((m) => m.AddDiasPedidoGranjaModule),
      },
      {
        path: "apps/movimiento-vaciado-nave",
        loadChildren: () =>
          import(
            "./pages/apps/notificaciones/movimiento-vaciado-nave/movimiento-vaciado-nave.module"
          ).then((m) => m.MovimientoVaciadoNaveModule),
      },
      {
        path: "apps/estadisticas-granja",
        loadChildren: () =>
          import(
            "./pages/apps/notificaciones/estadisticas-granja/estadisticas-granja.module"
          ).then((m) => m.EstadisticasGranjaModule),
      },
      //? Recetas
      {
        path: "apps/historico-recetas",
        loadChildren: () =>
          import("./pages/apps/recetas/historico-recetas/recetas.module").then(
            (m) => m.RecetasModule
          ),
      },
      {
        path: "apps/add-receta",
        loadChildren: () =>
          import("./pages/apps/recetas/add-recetas/add-recetas.module").then(
            (m) => m.AddRecetasModule
          ),
      },
      //? Control de almacen
      {
        path: "apps/control-almacen/articulos",
        loadChildren: () =>
          import(
            "./pages/apps/control-almacen/articulos/articulos.module"
          ).then((m) => m.ArticulosModule),
      },
      {
        path: "apps/control-almacen/add-articulo/:id",
        loadChildren: () =>
          import(
            "./pages/apps/control-almacen/add-articulo/add-articulo.module"
          ).then((m) => m.AddArticuloModule),
      },
      {
        path: "apps/control-almacen/importar-csv",
        loadChildren: () =>
          import(
            "./pages/apps/control-almacen/importar-csv/importar-csv.module"
          ).then((m) => m.ImportarCsvModule),
      },
      //? Movimientos de granja
      {
        path: "apps/movimientos-pienso",
        loadChildren: () =>
          import(
            "./pages/apps/movimientos-granja/movimientos-pienso/movimientos-pienso.module"
          ).then((m) => m.MovimientosPiensoModule),
      },
      {
        path: "apps/movimientos-medicamentos",
        loadChildren: () =>
          import(
            "./pages/apps/movimientos-granja/movimientos-medicamentos/movimientos-medicamentos.module"
          ).then((m) => m.MovimientosMedicamentosModule),
      },
      {
        path: "apps/movimientos-animales",
        loadChildren: () =>
          import(
            "./pages/apps/movimientos-granja/movimientos-animales/movimientos-animales.module"
          ).then((m) => m.MovimientosAnimalesModule),
      },
      //? Espacio virtual
      {
        path: "apps/virtual/carpetas",
        loadChildren: () =>
          import("./pages/apps/espacio-virtual/carpetas/carpetas.module").then(
            (m) => m.CarpetasModule
          ),
      },
      {
        path: "apps/virtual/carpetas-admin",
        loadChildren: () =>
          import(
            "./pages/apps/espacio-virtual/carpetas-admin/carpetas-admin.module"
          ).then((m) => m.CarpetasAdminModule),
      },
      {
        path: "apps/virtual/add-carpeta/:id",
        loadChildren: () =>
          import(
            "./pages/apps/espacio-virtual/add-carpeta/add-carpeta.module"
          ).then((m) => m.AddCarpetaModule),
      },
      //? Sellos
      /* {
        path: "apps/historico-sellos",
        loadChildren: () =>
          import(
            "./pages/apps/sellos/historico-sellos/historico-sellos.module"
          ).then((m) => m.HistoricoSellosModule),
      },
      {
        path: "apps/add-sello/:id",
        loadChildren: () =>
          import("./pages/apps/sellos/add-sello/add-sello.module").then(
            (m) => m.AddSelloModule
          ),
      }, */
      //?Productos
      {
        path: "apps/add-producto/:id",
        loadChildren: () =>
          import(
            "./pages/apps/productos/add-producto/add-producto.module"
          ).then((m) => m.AddProductoModule),
      },
      {
        path: "apps/historico-productos",
        loadChildren: () =>
          import(
            "./pages/apps/productos/historico-productos/historico-productos.module"
          ).then((m) => m.HistoricoProductosModule),
      },
      //ROLES
      {
        path: "apps/add_roles/:id",
        loadChildren: () =>
          import("./pages/apps/roles-permisos/add-roles/add-roles.module").then(
            (m) => m.AddRolesModule
          ),
      },
      {
        path: "apps/historico_roles",
        loadChildren: () =>
          import(
            "./pages/apps/roles-permisos/historico-roles/historico-roles.module"
          ).then((m) => m.HistoricoRolesModule),
      },
      //INFORMES
      {
        path: "apps/plantilla-informes",
        loadChildren: () =>
          import(
            "./pages/apps/informes/plantilla-informes/plantilla-informes.module"
          ).then((m) => m.PlantillaInformesModule),
      },
      {
        path: "apps/nueva-plantilla-informe/:id",
        loadChildren: () =>
          import(
            "./pages/apps/informes/nueva-plantilla-informe/nueva-plantilla-informe.module"
          ).then((m) => m.NuevaPlantillaInformeModule),
      },
      {
        path: "apps/informe-traslados",
        loadChildren: () =>
          import(
            "./pages/apps/informes/informe-traslados/informe-traslados.module"
          ).then((m) => m.InformeTrasladosModule),
      },
      {
        path: "apps/movimiento-animal",
        loadChildren: () =>
          import(
            "./pages/apps/movimiento-animal/movimiento-animal.module"
          ).then((m) => m.MovimientoAnimalModule),
      },
      {
        path: "apps/nuevo-movimiento-animal/:id",
        loadChildren: () =>
          import(
            "./pages/apps/movimiento-animal/nuevo-movimiento/nuevo-movimiento.module"
          ).then((m) => m.NuevoMovimientoModule),
      },
      {
        path: "apps/carga-transportista",
        loadChildren: () =>
          import(
            "./pages/apps/movimiento-animal/carga-transportista/carga-transportista.module"
          ).then((m) => m.CargaTransportistaModule),
      },
      { path: '**', redirectTo: '' }
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledNonBlocking",
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
