import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from '../../apps/shared/services/sotrage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private router: Router,
        private storageService: StorageService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = this.storageService.getItem('token');

        let request = req;

        if (token) {
            request = req.clone({
                setHeaders: {
                    authorization: `Bearer ${token}`
                }
            });
        }

        if (request.url.indexOf('/login') !== -1) {
            return next.handle(request);
        } else {
            return next.handle(request).pipe(
                catchError((err: HttpErrorResponse) => {
    
                    if (err.status === 401 || err.status === 403) {
                        sessionStorage.clear();
                        this.router.navigateByUrl('/login');
                    }
    
                    return throwError(err);
    
                })
            );
        }

    }

}