<div class="title">{{ current }}</div>
<div class="crumbs" fxLayout="row" fxLayoutAlign="start center">
  <!-- <a [routerLink]="['/']" class="crumb home">Inicio</a> -->
  <div *ngFor="let crumb of crumbs; index as i" class="crumb" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon *ngIf="i > 0" class="chevron">chevron_right</mat-icon>
    <a [routerLink]="crumb.url" class="link">{{ crumb.label }}</a>
  </div>
  <div class="crumb current" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="link">{{ current }}</div>
  </div>
</div>
