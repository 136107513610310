import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getItem(item:string): string | null {
    return sessionStorage.getItem(item);
  }

}