// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  // backend: 'http://localhost:4200',
  //backend: 'https://agropor4862.aunnaitapp.es:8443'
  //backend:'http://5.56.58.32:8080/api'
  backend:'https://pre-agropor.aunnaitapp.es/api'
};
