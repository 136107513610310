<div class="sidenav" fxLayout="column">

  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center">

    <div [routerLink]="['/']" class="sidenav-toolbar-logo">
      <img class="sidenav-user-image" src="assets/img/logo-grupo.png" style="width: 170px; margin-top: 20px;">
    </div>

  </div>

  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <!-- <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user">
      <h4 class="sidenav-user-name">David Smith</h4>
      <h5 class="sidenav-user-email">david.smith@example.com</h5>   
    </div>-->

    <fury-sidenav-item *ngFor="let item of items$ | async" [item]="item" [level]="0"></fury-sidenav-item>
  </fury-scrollbar>

</div>
